@import "../../styles/main.scss"; 

.ResultsTitle {
    text-align: center;
    font-weight: bold;
    margin: 0;
  }

  .Skills  {
    margin-top: $space-aquaintences;
    padding: $space-aquaintences 48px;
    @include card;
    background-color: $fifthiary; 
  }