@import "../../styles/main.scss";

// ************* HEADER SECTION ************ //
.Title {
  @include font-scale(x-large, medium);
}

.SectionWrapper {
  padding: 75px 0 0 0;

  @include mq($from: tablet) {
    padding:  $space-enemies 0;
    position: relative;
  }

  @include mq($from: wide) {
    padding: 200px 0 75px 0;
  }
}

.ContentContainer {
  margin-bottom: $space-strangers;

  @include mq($from: tablet) {
    margin-bottom: 0;
    width: 50%;
    padding-right: 32px;
  }
}

.ContainerWide {
  padding-left: $x3;
  padding-right: $x3;
  width: 100%;

  @include mq($from: desktop) {
    max-width: 1150px;
    margin: 0 auto;
}
}



// ************* MOBILE IMAGE GRID ************ //
.MobileImagesLayoutWrapper {
  position: relative;
}

.MobileImagesLayout {
  display: flex;
  //   justify-content: space-between;
  align-items: center;

  @include mq($from: tablet) {
    display: none;
  }
}

.MobileImage {
  z-index: 12;
  @include image;
  @include card;
  width: auto;
  max-height: 300px;
  margin: auto;
}

.MobileImagesLayout::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: $primary;
  z-index: 0;
}

// ************* DESKTOP IMAGE GRID ************ //

.DesktopImagesLayoutWrapper {
  display: none;
  @include mq($from: tablet) {
    display: block;
    position: absolute;
    bottom: 50%;
    left: calc(50% - 24px);
    transform: translate(0, 50%);
    width: 50%;

    @include mq($from: wide) {
      width: 490px;
    }
  }

  @include mq($from: wide) {
    bottom: -75px;
    left: 50%;
    transform: unset;
  }
}

.FeaturedImage {
  @include image;
  @include card;
  width: 100%;
  height: 345px;
}


.content {
  position: relative;
}