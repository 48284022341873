@import "../../styles/main.scss";

.UnsetLinkStyles {
  color: $textBlack;
  text-decoration: none;
}

.CardStyles {
  @include card;
  @include rotating-card;
  background: $black-haze;
}

.ClientSection {
  border-radius: $border-radius $border-radius 0 0;
  border-bottom: 2px solid lightgrey;
}

.ProjectSection {
  padding: $space-family;
}

.ProjectDescription {
  margin: 0;
}

.Tags {
  font-size: 14px;
}


.Research, .Wires {
  padding: 5px 7px;
  border-radius: 15px;
  font-size: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Research {
background: $tertiary;
margin-right: 8px;
}

.Wires {
  background: $secondary;
}