@import "../../styles/main.scss";

.SectionStyle {
    @include primary-section;
}

// @TODO title styles mixin?
.Title {
    margin-bottom: $space-friends;
    margin-top:0;
    text-align: center;

    @include mq($from: tablet) {
      margin-bottom: $space-aquaintences;
  }
}