@import "../../styles/main.scss";

// UNIVERSAL DEFAULTS & FLEXBOX FALLBACK

.ImageGallery {
  @include mq($from: tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// CSS GRID STYLES

@supports (display: grid) {
  .ImageGallery {
    display: grid;
    row-gap: 32px;

    @include mq($from: tablet) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
    }
  }
}

.Image {
  margin-top: 32px;
}