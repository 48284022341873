@import "../../styles/main.scss";

.Client {
  display: flex;
  align-items: center;
  padding: $space-family;
  justify-content: space-between;
}

.Name {
  margin: 0;
  // font-weight: bold;
  font-size: 20px;
}

.Logo {
  margin-right: $space-lovers;
  height: 40px;
}