
// Want primary colour to always be something professional and mature
// Want secondary colour to always be something with a bold, fun pop
// Tertiary wants to work well alongside secondary :) 
// All colours must work alongside the muted white used for text backgrounds

//*********************************************//
//************ PRIMARY COLOUR SCHEME **********//
//*********************************************//
$cloud-burst: #213951;
$goldenrod: #FFD66B;
$scooter: #2CAEBE;
$salt-pan: #F2F8F2;
$white: #ffffff;
$shark: #212427;

// use 'em
// $primary: $cloud-burst;
// $secondary: $goldenrod;
// $tertiary: $scooter;
// $fourthiary: $salt-pan;
// $textWhite: $white;
// $backgroundWhite: $white;
// $textBlack: $shark;

// LOVE
// $yellow: #f2b947;
// $blue: #4287f5;

$pampas: #f0ece6;

$apricot:   #f2b947;
$lilac:   #D6E9FF;
$lavendar:  #E8E6F3;
$mint: #C7F1D5;
$blue: #D6E9FF;

$teal: #00a8cc;
$pumpkin: #e67e22;
$dodgerblue: #3498db ;
$orange: #f39c12;
$cinabar: #e74c3c ;
$coral: #ff6b6b;
$orange: #ffa500;
$picked-bluewood: #2c3e50;

// LIKE?
// $primary: #635e72;

$primary:  $apricot;
$secondary: $teal;
$tertiary: $coral;
$fourthiary: $picked-bluewood;
$fifthiary: $pampas;

$textWhite: $white;
$backgroundWhite: $white;
$textBlack: $shark;

// Archive
$sunset-orange: #FE4B42;
$pink-lace: #FFD6F0;
$ice-cold: #BAF4E2;
$orient: #006A83;
$provincial-pink: #fdf2eb;
$gallery: #EFEFEF;
$black-haze: #F6F7F7;
$firefly: #081722;


//*********************************************//
//************** SYSTEM MESSAGES **************//
//*********************************************//
// Not applicable: noramlly include warning, success, and error

// @TODO: Consider updating accent colours to variables. Also consider background colour variables?
