//*********************************************//
//****************** DROP SHADOWS *************//
//*********************************************//

$zShadows: (
  0: (
    0 0 0 0 rgba(10, 22, 70, 0.1),
    0 0 0 0 rgba(10, 22, 70, 0.06),
  ),
  1: (
    0 1px 1px 0 rgba(10, 22, 70, 0.1),
    0 0 1px 0 rgba(10, 22, 70, 0.06),
  ),
  2: (
    0 3px 3px -1px rgba(10, 22, 70, 0.1),
    0 0 1px 0 rgba(10, 22, 70, 0.06),
  ),
  3: (
    0 6px 6px -1px rgba(10, 22, 70, 0.1),
    0 0 1px 0 rgba(10, 22, 70, 0.06),
  ),
  4: (
    0 16px 16px -1px rgba(10, 22, 70, 0.1),
    0 0 1px 0 rgba(10, 22, 70, 0.06),
  ),
  5: (
    0 32px 40px -2px rgba(10, 22, 70, 0.12),
    0 0 1px 0 rgba(10, 22, 70, 0.06),
  ),
);

@mixin floating-card($zIndex: 1) {
  box-shadow: map-get($map: $zShadows, $key: $zIndex);
}

@mixin card {
  border-radius: $border-radius;
  @include floating-card(2);
  background-color: $backgroundWhite;
}

//*********************************************//
//****************** EFFECTS ******************//
//*********************************************//

@mixin rotating-card() {
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    transform: rotate(5deg);
    transition: all 0.25s ease-in-out 0s;
  }
}

@mixin screenreaders-only() {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; 
}

//*********************************************//
//************* FREQUENT STYLES ***************//
//*********************************************//

@mixin image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin unset-ul {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }

  li:not(:last-of-type) {
    margin-right: $x4;
  }
}

@mixin primary-section {
  background-color: $primary;
  padding: $space-strangers 0;

  @include mq($from: wide) {
    padding:  $space-enemies 0;
  }
}

@mixin plain-section {
  background-color: $backgroundWhite;
  padding: $space-strangers 0;

  @include mq($from: wide) {
    padding:  $space-enemies 0;
  }
}

@mixin dark-section {
  background-color: $fourthiary;
  padding: $space-strangers 0;

  @include mq($from: wide) {
    padding:  $space-enemies 0;
  }
}

// @TODO: Make sure you're using these....and update other reused styles here :)