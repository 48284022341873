@import "../../styles/main.scss";

.Socials {
  margin-top: $space-aquaintences;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
}

.Social {
  display: inline-block;
}

.Social:not(:last-of-type) {
  margin-right: $space-friends;
}

// .SocialLink {
//   color: $link-color;
//   transition: all 0.2s ease-in-out;
//   max-height: 20px;

//   &:hover {
//     color: $link-hover-color;
//     transform: scale(1.1);
//   }
// }


.Footer {
  padding: $x3 0 ;
  background-color: $textWhite;
}