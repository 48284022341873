@import "~sass-rem";

//*********************************************//
//*************** IMPORT FONTS ****************//
//*********************************************//
@font-face {
  font-family: "gt-walsheim";
  src: url("../assets/fonts/GT-Walsheim-Black.otf") format("opentype");
  font-weight: 800 1000;
  font-style: normal;
}

@font-face {
  font-family: "gt-walsheim";
  src: url("../assets/fonts/GT-Walsheim-Regular.otf") format("opentype");
  font-weight: 400 599;
  font-style: normal;
}

$font-family: gt-walsheim, "Arial Narrow", Arial, sans-serif;

//*********************************************//
//*************** CREATE MIXINS ***************//
//*********************************************//

$font-default-values: (
  stack: $font-family,
  small: (
    font-size: 18px,
    line-height: 1.4,
  ),
  base: (
    font-size: 18px,
    line-height: 1.4,
  ),
  mid: (
    font-size: 20px,
    line-height: 1.4,
  ),
  mid-large: (
    font-size: 24px,
    line-height: 1.4,
  ),
  large: (
    font-size: 28px,
    line-height: 1.4,
  ),
  x-large: (
    font-size: 36px,
    line-height: 1.4,
  ),
  super: (
    font-size: 56px,
    line-height: 1.4,
  ),
  crazy: (
    font-size: 72px,
    line-height: 1.4,
  ),
);

$weights: (
  normal: 400,
  medium: 600,
  bold: 800,
);

@function _fontset-feature($set, $feature) {
  $result: map-get(map-get($font-default-values, $set), $feature);
  @return $result;
}

@function get-font-size($set) {
  @return _fontset-feature($set, font-size);
}

@function get-line-height($set) {
  @return _fontset-feature($set, line-height);
}

@mixin font-scale($font-size: "base", $font-weight: "normal") {
  @if (map-has-key($map: $font-default-values, $key: $font-size) != true) {
    @error ("could not find given font size: #{$font-size}");
  }
  @if (map-has-key($map: $weights, $key: $font-weight) != true) {
    @error ("could not find given font weight: #{$font-weight}");
  }
  @each $property, $value in map-get($font-default-values, $font-size) {
    #{$property}: $value;
  }
  font-size: var(--fs-#{$font-size}, rem(get-font-size($font-size)));
  line-height: var(--lh-#{$font-size}, rem(get-line-height($font-size)));
  font-weight: map-get($weights, $font-weight);
}

@mixin font-weight($font-weight: "normal") {
  @if (map-has-key($map: $weights, $key: $font-weight) != true) {
    @error ("could not find given font weight: #{$font-weight}");
  }
  font-weight: map-get($weights, $font-weight);
}

//*********************************************//
//********** SET DEFAULT FONT SIZES **********//
//*********************************************//

p,
a,
li {
  @include font-scale(base, normal);
}

h1 {
  @include font-scale(mid-large, normal);
}

h2 {
  @include font-scale(mid-large, normal);
}

h3 {
  @include font-scale(base, normal);
}

input,
button {
  font-family: $font-family;
}

// @TODO: Check if these should be using rem instead of pixels?
// @TODO: check you are using these!!!