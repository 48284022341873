@import "../../styles/main.scss"; 

.Title {
  margin-top: 0;
  margin-bottom: $space-lovers;
  @include font-scale(base, medium);
  color: #2c3e50;
}

.Subtitle {
  margin: 0;
  margin-bottom:$space-family;
  font-size: 16px;

  @include mq($from: tablet) {
    margin-bottom: $space-lovers;
  }
} 

.Summary {
  margin: 0;
}

.Step {

    &::before, &::after {
      content: '';
      position: absolute;
    }

    &:not(:last-child) {
      padding-bottom: 48px;
    }
  
    // timeline dot
    &::before{
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: -25px;
      z-index: 100;
      top: 7px;
      background-color: $coral;
    }
  
    // timeline line
    &::after {
      width: 2px;
      height:100%;
      top: 7px;
      left: -21px;
      background-color: #2c3e50;
    }
  }

  // MOBILE STYLES

  .DesktopInterest {
    display: none;
  }

  .Step {
    position: relative;
    padding-top: 45px;
  }

  .MobileTag {
    //tag
    background: #2c3e50;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 5px;
    color: white;
    border-radius: 3px;

    // tag arrow
    &::before {
      content: "";
      position: absolute;
      left: -14px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid #2c3e50;
      top: 50%;
      transform: translate(0%, -50%);
      }
  }

  .Years {
    margin-bottom: 0;
  }

.MobilePhoto {
  margin-bottom: $space-friends;
  min-height: 300px;
  min-width: 100%;
  @include image;
  @include card;
}

  // TABLET UP STYLES
  .Step {
    @include mq($from: tablet) {
      padding-top: 0;
    }

    &:not(:last-child) {
      @include mq($from: tablet) {
        padding-bottom: 32px;
      }
    }
  } 

  .MobileTag, .MobilePhoto {
    @include mq($from: tablet) {
      display: none;
    }
  }

  .DesktopInterest {
    @include mq($from: tablet) {
      display: unset; 
      position: absolute;
      left: -245px;
      top: 0;
      width: auto;
    }
  }

  .TagWrapper {
    width: 100%;
    position: relative;

    @include mq($from: tablet) {
      // left: -143px;
      // z-index: 100;
      margin-bottom: 8px;
  
      &::before {
        content: "";
        position: absolute;
        right: -14px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid transparent;
        border-left: 7px solid #2c3e50;
        top: 50%;
        transform: translate(0%, -50%);
        }
    }
  }

  .Years {
    @include mq($from: tablet) {
    // display: none;
    width: fit-content;
    background: #2c3e50;
    // display: block;
    text-align: right;
    margin-left: auto;
    padding: 3px 5px;
    color: white;
    border-radius: 3px;

    &::after {
      content: "";
      position:absolute;
      top:0px;
      left:0px;
      height: auto;
      width: 100%;
      background-color: yellow;
    }
  }
}

  .Photo {
    @include mq($from: tablet) {
      // position: absolute;
      // left: -250px;
      // top: 35px;
      min-height: unset;
      height: 150px;
      min-width: 200px;
      margin-bottom: unset;
      @include image;
      @include card;
    }
  }
