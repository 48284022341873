@import "../../styles/main.scss";

// CREATE SECTION BACKGROUND
.SectionStyle {
    @include primary-section;
  }

  // ADD SECTION TITLE
  //@TODO use title mixin
  .Title {
    text-align: center;
    margin-bottom: $space-friends;
    margin-top:0;

    @include mq($from: tablet) {
      margin-bottom: $space-aquaintences;
    }
  }

  .ReviewerName {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: $space-lovers;
  }
  
  .ReviewerJob {
    margin-top: 0;
    margin-bottom: $space-lovers;
  }

  .ReviewerCompany {
    margin: 0;
  }

  // ADD WHITE BUBBLE AROUND QUOTE
  .QuoteWrapper {
    position: relative;
    @include card;
    padding: 24px;
  
    // ADD LITTLE ARROW AT BOTTOM OF QUOTE
    @include mq($from: tablet) {padding: 50px; }
      &::before {
        content: "";
        position: absolute;
        bottom: -40px;
        left: 10%;
        border-top: 20px solid $backgroundWhite;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
    }
  }
  
  // GIVE REVIEWER DETAILS
  .Attribution {
    display: flex;
    align-items: center;
    margin-top: $space-aquaintences;
    @include image;
  }

  // ADD REVIEWER PHOTO
  .Photo {
    background-size: cover;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 25px;
  }
  
  .Reviewer {
    line-height: 1;
  }

  // HIGHLIGHT KEY QUOTE
  .Standout {
    font-size: 24px;
    border-top: 2px solid $teal;
    border-bottom: 2px solid $teal;
    padding-top: $space-family;
    padding-bottom: $space-family;
    color: teal;
    font-weight: bold;
  }

.TestimonialLead {
  margin-top: 0;
}

.TestimonialFinisher {
  margin-bottom: 0;
}

  // @TODO: refactor this post-MVP.