@import "../../styles/main.scss";

.Section {
  padding: $space-strangers 0;

  @include mq($from: wide) {
    padding:  $space-enemies 0;
  }
}

.Section_pro {
  background-color: $primary;
}

.Section_secondary {
  background-color: $secondary;
}

.Section_tertiary {
  background-color: $tertiary;
}

.Section_four {
  background-color: $textWhite;
}