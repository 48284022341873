//*********************************************//
//****************** BASE UNIT ****************//
//*********************************************//
$size-unit: 8px;

//*********************************************//
//********** MULTIPLYER DECLARATIONS **********//
//*********************************************//
$x1: $size-unit;
$x2: $size-unit * 2;
$x3: $size-unit * 3;
$x4: $size-unit * 4;
$x8: $size-unit * 8;
$x16: $x8 * 2;

// use 'em
$space-lovers: $x1;  // space between atoms, 8
$space-family: $x2; // space between molecules, 16
$space-friends: $x3; // space between internal elements, 24
$space-aquaintences: $x4; // space around components, 32
$space-strangers: $x8; // mobile section space, 64
$space-enemies: $x16; // wide section space, 128

// @TODO: Make sure you're using these!!!