@import "../../styles/main.scss";

// UNIVERSAL DEFAULTS & FLEXBOX FALLBACK

.GalleryImage {
  position: relative;
  min-height: 300px;
  @include image;
  border-radius: 8px;
  @include floating-card(3);
  margin-bottom: $space-aquaintences;

  @include mq($from: tablet) {
    width: 48%;
    min-height: 400px;
  }
}

.Tag {
  background: $fifthiary;
  display: inline-block;
  padding: 10px 25px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  font-size: 18px;
}

.Tag__topleft {
  left: 15px;
  top: 15px;
}

.Tag__topright {
  right: 15px;
  top: 15px;
}

.Tag__bottomright {
  right: 15px;
  bottom: 15px;
}

.Tag__bottomleft {
  left: 15px;
  bottom: 15px;
}

// // CSS GRID STYLES

@supports (display: grid) {
  .GalleryImage {
    margin-bottom: 0;
  }

  @include mq($from: tablet) {
    .GalleryImage {
      width: auto;
    }

    .TallImage {
      grid-row: span 2;
    }

    .WideImage {
      grid-column: span 2;
    }
  }

  @include mq($from: desktop) {
    .GalleryImage {
      min-height: 350px;
    }
  }
}
