@import "../../styles/main.scss";

.Container {
  padding-left: $x3;
  padding-right: $x3;
  width: 100%;
}

@include mq($from: desktop) {
  .Container {
    max-width: 1024px;
    margin: 0 auto;
  }
}


