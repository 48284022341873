@import "../../styles/main.scss";

.Hidden {
  @include screenreaders-only;
}

.Title {
@include font-scale(mid-large, normal);
margin-bottom: $space-family;
}

.Body {
  margin-bottom: 0;
}

// ************* HEADER SECTION ************ //

.SectionWrapper {
  padding: 75px 0 0 0;

  @include mq($from: tablet) {
    padding:  $space-enemies 0;
    position: relative;
  }

  @include mq($from: wide) {
    padding: 200px 0 75px 0;
  }
}

.ContentContainer {
  margin-bottom: $space-strangers;

  @include mq($from: tablet) {
    margin-bottom: 0;
    width: 50%;
    padding-right: 32px;
  }
}

// ************* MOBILE IMAGE GRID ************ //
.MobileImagesLayoutWrapper {
  position: relative;
}

.MobileImagesLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq($from: tablet) {
    display: none;
  }
}

.MobileImage {
  z-index: 12;
  @include image;
  @include card;
  width: 48%;
  height: auto;
}

.MobileImagesLayout::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: $primary;
  z-index: 0;
}

// ************* DESKTOP IMAGE GRID ************ //

.DesktopImagesLayoutWrapper {
  @include mq($from: tablet) {
    position: absolute;
    display: flex;
    bottom: 50%;
    left: calc(50% - 24px);
    transform: translate(50%, 50%);
  }

  @include mq($from: wide) {
    bottom: -75px;
    left: 50%;
    transform: unset;
  }
}

.GridImage {
  @include image;
  @include card;
  width: 48%;
}

.FeaturedImage {
  width: 240px;
  height: 345px;
}

.SecondaryImage {
  @include mq($from: tablet) {
    display: none;
  }

  @include mq($from: wide) {
    display: block;
    height: 224px;
    width: 208px;
    margin-bottom: $space-family;
  }
}

.SupplementalImage {
  @include mq($from: tablet) {
    display: none;
  }

  @include mq($from: wide) {
    display: block;
    height: 150px;
    width: 186px;
  }
}

.LeftColumn,
.RightColumn {
  display: none;

  @include mq($from: tablet) {
    display: flex;
    flex-direction: column;
  }
}

.LeftColumn {
  @include mq($from: wide) {
    margin-right: $space-family;
  }
}

