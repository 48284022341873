
@import "../../styles/main.scss";


.TestimonialFancyWrapper {
    margin-top: 86px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    @include mq($from: tablet) {
      flex-direction: row;
      margin-top: 0;


      &:not(:last-of-type) {
        margin-bottom: 48px;
      }
    }
  }

  
  .TestimonialFancy {
  padding: 24px;
  background: white;
  @include card;
  position: relative;
  padding-top: 35px;
  width: 100%;

  @include mq($from: tablet) {width: fit-content;}
  
  
  .TestimonialFancyBody {
    margin-bottom: 8px;
    margin-top: 0;
  }
  
  @include mq($from: tablet) {
  &::before {
    content: "";
    position: absolute;
    left: -40px;
    top: 50%;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
    border-right: 20px solid $backgroundWhite;
    transform: translate(0, -50%);
  }
  }
  }
  
  .TestimonialFancyPhoto {
    position: absolute;
    min-width: 75px;
    min-height: 75px;
    border-radius: 50%;
    display: block;
    background: grey;
    @include image;
    top: -50px;
    z-index: 1;

    @include mq($from: tablet) {
      margin-right: 32px;
      position: relative;
      top: unset;
    }
  }


  .Reviewer {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  