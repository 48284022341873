@import "../../styles/main.scss";

.ReelCarousel {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $space-strangers;
  justify-items: center;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, auto);

  @include mq($from: tablet) {
    grid-template-columns: repeat(5, auto);
    justify-content: space-between;
    grid-gap: $space-aquaintences;
    align-items: center;
  }
}

.Logo {
  height: 75px;
}

.LoTLogo {
  width: 165px;
}

.Hidden {
  @include screenreaders-only;
}

.PrimarySection {
@include dark-section;
}