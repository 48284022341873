@import "../../styles/main.scss";

// ADD PADDING ON THE SIDES ON MOBILE
.ExperienceWrapper {
    @include plain-section;
    
    // REMOVE THE PADDING ON THE SIDES ON LARGE DEVICES

  }

  .Spacer {
    padding-left: $space-aquaintences;
    padding-right: $space-aquaintences;

    @include mq($from: wide) {
      padding-left: unset;
      padding-right: unset;
    }
  }
  // STYLE THE SECTION HEADER
  // @TODO: opportunity for a mixin
  .Title {
    text-align: center; 
    margin-bottom: $space-friends;
    margin-top:0;

    @include mq($from: tablet) {
      margin-bottom: $space-aquaintences;
    }
  }

  // CREATE SKILLS SECTION CARD
  .Skills  {
    margin-top: $space-aquaintences;
    padding: $space-aquaintences 48px;
    @include card;
    background-color: $fifthiary; 
  }
  
    // CREATE SUBTITLES WITH LINE UNDERNEATH
    // @TODO: opportunity for a mixin
  .SkillTitle {
    position: relative; 
    margin-top: 0;
    margin-bottom: $space-family;

    &::after {
      content: '';
      width: 30px;
      border-bottom: solid 2px $secondary;
      position: absolute;
      left: 0;
      bottom: -5px;
      z-index: 1;
    }
    
    // Alternate title highlight style available
    // background: linear-gradient(180deg, rgba(255,255,255,0) 65%, $teal 65%);
    // width: auto;
    // display: inline-block;
  }

  // POSITION BODY TEXT
  .SkillBody {
    margin: 0;
  }

  // Refactor when you refactor the tsx