@import "./styles/main.scss";

* {
  box-sizing: border-box;
}

.hidden {
  @include screenreaders-only;
}

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  background-color: $backgroundWhite;
}

// **********************************************************//
// ****************** WORK IN PROGRESS CODE *****************//
// ***** TO BE MOVED INTO COMPONENT FILES WHEN DONE *********//
.Background {
  background: $fifthiary;
}





.Table {
  border-radius: 5px;
  @include card;
  width: 100%; 
  border-collapse: collapse; 
  margin-bottom: 64px;

  tr:nth-of-type(odd) { 
  background: #eee; 
}


}

.Row {
  border: 1px solid green;
}

.Head {
  background: #333; 
  color: white; 
  font-weight: bold; 
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: left; 
}

.Cell {
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: left; 
}

img {
  max-width: 100%;
}

.NextStepsTitle {
  text-align: center;
  margin-bottom: 32px;
}

.NextStepsBody {
  text-align: center;
  margin-top: 32px;
}

// @TODO: move this
.AboutPageTitle {
  margin-top: 120px !important;
  text-align: center;
}

.PageTitle {
  @include font-scale(x-large, medium);
}

.StepTitle {
  margin-top: 0;
  margin-bottom: $space-lovers;
  @include font-scale(large, medium);
  color: #2c3e50;
  margin-bottom: 8px;
}

.Activity {
  margin-bottom: 48px;
}

.ApproachSection {
  background: $pampas;
}

.Question {
color: $fourthiary;
margin-top: 0;
margin-bottom: 24px;
font-style: italic;
@include font-scale(mid, normal);
}

.ListWrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.Challenge {
  margin-top: 42px;
}

.FirstP {
  margin-top: 0;
}

.TestimonialsTitle {
  margin-top: 0;
  font-weight: bold;
  text-align: center;

  @include mq($from: tablet) {
    margin-bottom: 32px;
  }
}

.Subtitle {
  @include font-scale(mid-large, normal);
  margin-top: 0;
  margin-bottom: 16px;
  display: inline-block;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    background: linear-gradient(180deg, rgba(255,255,255,0) 65%, $primary 65%);
    width: auto;
    -webkit-transform: rotate(-1.7deg);
    -moz-transform: rotate(-1.7deg);
    width: 100%;
    height: 30px;
    display: block;
    bottom: 5px;
    left: 0;
    position: absolute;
    opacity: .8;
    z-index: -1;
  }
}



.Test {
  display: flex;
  min-height: 100%;
}

.TestImage {
  width: 50%;
  min-height: 100%;
  display: block;
  background-color: $pampas;
  @include image;
}

.TestBody {
  width: 50%;
  padding-right: 16px;
}

.Header {
  border-bottom: 1px solid lightgrey;
  padding: 24px;
}

.Activity {
  // background: #EFEFEF;
  // padding: 24px;
  border-radius: 10px;
  background: $white;
  padding: 24px;
}

.Image {
  width: 100%;
  height: 250px;
  @include card;  
  background: grey;
}

.ActivitySection {
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.ReviewWrapper {
// border: 1px solid red;
position: relative;
margin-bottom: 32px;

// &::after {
// content: '';
// width: 20px;
// border-bottom: solid 2px $secondary;
// position: absolute;
// left: 0;
// bottom: -5px;
// z-index: 1;
// }
}

.Overview {
  background: $secondary;
}

.ProjectResult {
  @include card;
  padding: 18px;
  margin-top: 64px;
  border: 1px solid $primary;
}

.ResultsTitle {
  text-align: center;
  font-weight: bold;
  margin: 0;
}


.Review {
  margin-top: 0;
  margin-bottom: 8px; 

}



.SectionTitle {
  text-align: center;
  font-weight: bold;
}

.ProjectTestimonial {
  background: $primary;

}

.TestimonialWrapper {
  padding: 32px;
  @include card;
}


.ActivitySection {
  border: 1px solid $pampas;
  padding: 24px;
}

.Finding {
  margin-top: 24px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.FindingTitle {
  margin-top: 0;
  @include font-scale(mid, normal);
  margin-bottom: 8px;
  text-decoration: underline;
  text-decoration-color: $primary;
  text-decoration-thickness: 3px;
}

.ListItemLink {
  color: blue;
  text-decoration: link;
}

.Text {
  margin-top: 0;
  margin-bottom: 0;
}

.Body {
  padding: 24px;
}

.Quote {
  border-left: 5px solid red;
}

.ParticipantsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.Participant {
  // font-size: 14px;
  // padding: 5px 7px;
  // border-radius: 15px;
  // font-size: 12px;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // background: $tertiary;
  // display: inline-block;
  display: flex;
  background: grey;
  padding: 8px 16px;
  border-radius: 10px;
  width: fit-content;
  @include card;
  background: $pampas;
  font-size: 14px;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 16px;
  // Sort out margin-bottom
}

.ParticipantImage {
background: lightgrey;
border-radius: 50%;
margin-right: 16px;
height: 50px;
width: 50px;
@include image;

}


.ParticipantName {
  margin-bottom: 8px;
  // font-weight: bold;
  font-size: 16px;
}

.ParticipantRole {
  font-weight: 14px;
}

// .Count {
// color: $primary;
// }

.Callout {
  // border: 1px solid red;
  background: $primary;
  // color: white;
}

.CalloutText {
  @include font-scale(mid-large, normal);

}

// ADD PADDING ON THE SIDES ON MOBILE
.ExperienceWrapper {
    @include plain-section;
    
    // REMOVE THE PADDING ON THE SIDES ON LARGE DEVICES

  }

  .Spacer {
    padding-left: $space-aquaintences;
    padding-right: $space-aquaintences;

    @include mq($from: wide) {
      padding-left: unset;
      padding-right: unset;
    }
  }
  // STYLE THE SECTION HEADER
  // @TODO: opportunity for a mixin
  .Title {
    text-align: center; 
    margin-bottom: $space-friends;
    margin-top:0;

    @include mq($from: tablet) {
      margin-bottom: $space-aquaintences;
    }
  }

  // CREATE SKILLS SECTION CARD
  .Skills  {
    margin-top: $space-aquaintences;
    padding: $space-aquaintences 48px;
    @include card;
    background-color: $fifthiary; 
  }
  
    // CREATE SUBTITLES WITH LINE UNDERNEATH
    // @TODO: opportunity for a mixin
  .SkillTitle {
    position: relative; 
    margin-top: 0;
    margin-bottom: $space-family;

    &::after {
      content: '';
      width: 30px;
      border-bottom: solid 2px $secondary;
      position: absolute;
      left: 0;
      bottom: -5px;
      z-index: 1;
    }
    
    // Alternate title highlight style available
    // background: linear-gradient(180deg, rgba(255,255,255,0) 65%, $teal 65%);
    // width: auto;
    // display: inline-block;
  }

  // POSITION BODY TEXT
  .SkillBody {
    margin: 0;
  }

  // Refactor when you refactor the tsx