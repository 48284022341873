@import "../../styles/main.scss"; 


// LAYOUT
.UnsetLink {
  text-decoration: none;
  color: unset;
  font-size: unset;
}

.BarLayout{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $x3 0;
 
  
    @include mq($from: tablet) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .ListStyle {
    @include unset-ul;
    margin-top: 24px;

    @include mq($from: tablet) {
      margin-top: 0;
    }
  }

  .NavLink {
    color: unset;
    padding: 0 .25rem;
    margin: 0 -.25rem;
    text-decoration: none;
    background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      #000 50%
    );

    background-size: 200% 100%;
    background-position: -100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;

    &:before {
      content: '';
      background: $secondary;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 3px;
    }

    &:hover {
      background-position: 0;
     }

     &:hover::before {
      width: 100%;
    }

    // transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

    // &:hover {
    //   color: #fff;
    //   box-shadow: inset 200px 0 0 0 $secondary;
    // }


  }

// STYLE

.BarStyle {
    border-bottom: 1px solid lightgrey;
  }

.Title {
    margin: 0;
    @include font-scale(mid-large, normal);

    @include mq($from: tablet) {
        margin-bottom: 0;
    }
  }
  
  .Colour {

    color: $teal;
  }