@import "../../styles/main.scss";

.Grid {
  display: grid;
  align-items: stretch;
  grid-gap: $space-aquaintences;
}

.Grid_two {
  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.Grid_twoThirds {
  @include mq($from: tablet) {
    grid-template-columns: 1fr 2fr;
    column-gap: 50px;
  }
}


.Grid_three {
  @include mq($from: tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
